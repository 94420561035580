

























import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { mapGetters } from "vuex";
import { IRole } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";
import { getPluralNoun } from "@monorepo/utils/src/utils/pluralNoun";

export default defineComponent({
  name: "ForbiddenDeleteRoleModal",
  props: {
    roleId: {
      type: String,
      required: true,
    },
    userQty: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters("systemAccessesView", ["roles"]),
    roleName(): string {
      return this.roles.find((role: IRole) => +role.id === +this.roleId)?.name ?? "";
    },
    userTitle(): string {
      return getPluralNoun(this.userQty, "сотрудник", "сотрудника", "сотрудников");
    },
  },
  methods: {
    openRoleModal() {
      eventBus.$emit("OPEN_ROLE_MODAL", true);
      this.closeModal();
    },
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
  },
});
